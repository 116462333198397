<template>
  <div>
    <header class="stylePbm">
      <h1>PBM</h1>
      <router-link
        style="width: 191px; height: 0px; margin-right: 20px;"
        :to="{ name: 'addOrUpdatePbm', params: { activeRegister } }"
      >
        <button class="btn-addNewPbm">Incluir</button>
      </router-link>
    </header>
    <section class="searchPbm">
      <h6>Pesquisa de PBM</h6>
      <div>
        <img src="@/assets/img/lupa.png" alt="Lupa">
        <input type="text">
      </div>
    </section>
    <main class="contentPbm">
      <table class="tablePbm">
        <tr>
          <div style=
          "
            display: flex;
            justify-content: space-between;
            width: 200px;
          ">
            <th>PBM</th>
            <th>Nome</th>
          </div>
            <th style="font-size: 0.8em; width: 50px;">Vizualizar</th>
            <th style="font-size: 0.8em; width: 50px;">Editar</th>
            <th style="font-size: 0.8em; width: 50px;">Excluir</th>
        </tr>
        <tr style="background-color: #e6e6e6;">
          <div style=
          "
            display: flex;
            justify-content: space-between;
            width: 200px;
          ">
            <td>TPN12461030</td>
            <td style="margin-left: 65px;">Teste</td>
          </div>
          <td>
            <img width="28px" height="28px" src="@/assets/img/view.svg" alt="Icone de vizualização">
          </td>
          <td>
            <img width="18px" height="18px" src="@/assets/img/editar.svg" alt="Icone de editar">
          </td>
          <td>
            <img width="35px" height="35px"  src="@/assets/img/excluir.svg" alt="Icone de excluir">
          </td>
        </tr>
      </table>
    </main>  
    <!-- <div class="content-btn-addReturn">
      <router-link style="width: 0px" to="/dashboard">
        <button class="pbm-btn-return">
          {{ $t("sectionRegisters.backDashbord") }}
        </button>
      </router-link>
      <router-link
        style="width: 0px"
        :to="{ name: 'addOrUpdatePbm', params: { activeRegister } }"
      >
        <button class="pbm-btn-add">Adicionar PBM</button>
      </router-link>
    </div>
    <h1 class="titleViewPbm">Cadastros PBM</h1>
    <label class="content-searchPbm">
      <span>Pesquisar por um PBM</span>
      <input v-model="search" type="text" placeholder="Digite um nome" />
      <button @click="searchPbm">{{ $t("sectionSiproquim.search") }}</button>
    </label>
    <table class="content-tablePbm">
      <tr class="header-table">
        <th>Integração ID</th>
        <th>Nome</th>
        <th>Observação</th>
      </tr>
      <tr class="items-table" v-for="(pbm, index) in statePbm" :key="index">
        <td>{{ pbm.integracaoId }}</td>
        <td>{{ pbm.nome }}</td>
        <td>{{ pbm.observacao }}</td>
        <span class="content-btn-pbmEditOrDelete">
          <router-link
            :to="{ name: 'addOrUpdatePbm', params: { activeEdit, pbm } }"
          >
            <button class="editStyle">
              {{ $t("sectionRegisters.edit") }}
            </button>
          </router-link>
          <button class="deleteStyle" @click="deletePbm(pbm)">
            {{ $t("sectionRegisters.delete") }}
          </button>
        </span>
      </tr>
    </table>
    <section class="content-pagePbm">
      <button class="backPage" @click="backPage">
        {{ $t("sectionSiproquim.pagesPrevious") }}
      </button>
      <button class="nextPage" @click="nextPage">
        {{ $t("sectionSiproquim.pagesNext") }}
      </button>
    </section> -->
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ViewPbm",
  data() {
    return {
      activeRegister: true,
      activeEdit: true,
      search: "",
      foundPbm: "",
      pageCurrent: 1,
    };
  },

  mounted() {
    this.$store.dispatch("pbmState/totalPbm");
  },

  watch: {
    search() {
      this.$store.dispatch("pbmState/totalPbm");
      console.log(this.statePbm);
    },
  },

  methods: {
    deletePbm(pbm) {
      const { statePbm } = this;

      let auth = localStorage.getItem("userKey");
      let authStr = JSON.parse(auth);

      axios
        .post(`${process.env.VUE_APP_BASE_URL}/pbm/remover/${pbm.id}`, true, {
          headers: { Authorization: `Bearer ${authStr}` },
        })
        .then(() => console.log("Deu tudo certo."))
        .catch(() => console.log("Ocorreu um erro."));

      const findPbm = statePbm.find((element) => element.id == pbm.id);
      const indexPbm = statePbm.indexOf(findPbm);

      statePbm.splice(indexPbm, 1);
    },

    searchPbm() {
      const checkSearch = this.search == "" || this.search == " ";

      const findPbm = () => {
        const filterPbm = this.statePbm.filter((element) => {
          return element.nome.toLowerCase() == this.search.toLowerCase();
        });

        this.foundPbm = filterPbm;
      };

      if (!checkSearch) {
        findPbm();

        if (this.foundPbm) {
          return this.$store.commit("pbmState/SET_PBM", this.foundPbm);
        } else {
          this.$store.dispatch("pbmState/totalPbm");

          return alert("Nenhum siproquim encontrado");
        }
      } else {
        return alert("Nenhum siproquim encontrado");
      }
    },

    async backPage() {
      let auth = localStorage.getItem("userKey");
      let authStr = JSON.parse(auth);

      this.pageCurrent--;

      if (this.pageCurrent >= 1) {
        const reqPages = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/pbm/obter-grid/${this.pageCurrent}`,
          {
            headers: { Authorization: `Bearer ${authStr}` },
          }
        );

        return this.$store.commit("pbmState/SET_PBM", reqPages.data.itens);
      } else {
        alert("Você já esta na primeira página");

        return (this.pageCurrent = 1);
      }
    },

    async nextPage() {
      let auth = localStorage.getItem("userKey");
      let authStr = JSON.parse(auth);

      this.pageCurrent++;

      const pages = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/pbm/obter-grid/1`,
        {
          headers: { Authorization: `Bearer ${authStr}` },
        }
      );

      if (this.pageCurrent <= pages.data.totalDePaginas) {
        const reqPages = await axios.get(
          `${process.env.VUE_APP_BASE_URL}/pbm/obter-grid/${this.pageCurrent}`,
          {
            headers: { Authorization: `Bearer ${authStr}` },
          }
        );

        return this.$store.commit("pbmState/SET_PBM", reqPages.data.itens);
      } else {
        alert("Alcançado o limite de páginas");

        return (this.pageCurrent = pages.data.totalDePaginas);
      }
    },
  },

  computed: {
    statePbm() {
      const { registerPbm } = this.$store.state.pbmState;

      return registerPbm;
    },
  },
};
</script>

<style scoped>
main {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 10px 50px 0 150px;
  left: 301px;
  width: 81%;
  margin-left: 0%;
}

.content-btn-addReturn {
  display: flex;
}

.pbm-btn-return {
  padding: 2px;
  width: 150px;
  margin-left: 10px;
  border: 1.5px gray solid;
  border-radius: 5px;
}

.pbm-btn-add {
  padding: 2px;
  width: 150px;
  margin-left: 910px;
  border: 1.5px gray solid;
  border-radius: 5px;
}

.pbmViewSipro {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.content-searchPbm {
  padding: 10px;
  /* border: 1px red solid; */
}
.content-searchPbm span {
  font-weight: bold;
  font-size: 1.2em;
}

.content-searchPbm input {
  margin-left: 5px;
  padding: 2px;
}

.content-searchPbm button {
  margin-left: 4px;
  padding: 2.5px;
  border: 1.5px gray solid;
  border-radius: 5px;
}

.content-tablePbm {
  border: 1px black solid;
}

.content-tablePbm .header-table {
  font-weight: bold;
  font-size: 1.1em;
  border: 2px black solid;
}

.content-tablePbm .items-table {
  /* margin: 50px; */
  border: 0.8px gray solid;
}

.content-tablePbm .items-table:hover {
  background-color: gray;
  color: black;
  font-weight: bold;
}

.content-btn-pbmEditOrDelete .editStyle {
  margin: 3px;
  padding: 1px;
  color: black;
  border: 1.5px black solid;
  border-radius: 5px;
}

.content-btn-pbmEditOrDelete .deleteStyle {
  margin-left: 3px;
  padding: 1px;
  color: black;
  background-color: red;
  border: 1px black solid;
  border-radius: 5px;
}

.content-pagePbm {
  text-align: center;
  margin-top: 10px;
}
.content-pagePbm .backPage {
  padding: 3px;
  margin-right: 10px;
  font-size: 1em;
  font-weight: bold;
  background-color: turquoise;
  border: 1px solid;
  border-radius: 5px;
}

.content-pagePbm .nextPage {
  padding: 3px;
  margin-left: 10px;
  font-size: 1em;
  font-weight: bold;
  background-color: turquoise;
  border: 1px solid;
  border-radius: 5px;
}
</style>

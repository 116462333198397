<template>
  <div>
    <ViewPbm />
  </div>
</template>

<script>
  import ViewPbm from '@/components/registerPbm/ViewPbm.vue';

  export default {
    components: {
      ViewPbm,
    }
  }
</script>